<template>
<div class="app-container">
  <!-- banner -->
  <div class="banner">
    <img  :src="bannerData.pictureUrl" class="pos-rl" v-if="bannerData.picture" :onerror="getImage(bannerData)" alt="" />
    <div class="left">
      <h2>About</h2>
      <div class="intro-text">
        You are here:  HomePages / About
      </div>
    </div>
  </div>
  <!-- about -->
  <div class="about columns inner" v-if="pageData[1]">
    <!-- <div class="center-header" >
      <h3>{{pageData[1].title}}</h3>
      <span>{{pageData[1].contentManagement.description}}</span>
    </div> -->
    <div class="head-title" v-html="pageData[1].name"></div>
    <div class="about-box dis-flex">
      <!-- 简介 -->
      <div class="left">
      <div class="img">
        <!-- <img :src="pageData[1].pictureUrl" v-if="pageData[1].picture" alt="" /> -->
        <img  :src="pageData[1].pictureUrl" v-if="pageData[1].picture" :onerror="getImage(pageData[1])" alt="" />
        <img src="../../static/client.png" v-else alt="">
      </div>
      </div>
      <div class="right">
        <!-- <div class="title" v-html="pageData[1].name"></div> -->
        <div class="content" v-html="pageData[1].contentManagement.content">
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Cookies from 'js-cookie'
import router from '@/router'
import {post, del, get, putUrl} from "@/api/http";
import request from "@/utils/request.js";

export default {
  name: 'Home',
  // components: { InfoDialog, UpdatePassword,TagsView, sideMenu},
  data() {
    return {
      pageData:[],
      bannerData:{},
      parentData:{},
    }
  },
  methods: {
    getData(){
      if(this.parentData.id){
        post("/publicController/publicData",{id:this.parentData.id}).then(res => {
            if (res.code == 1) {
                this.pageData=res.data.business
                this.bannerData=res.data.homeIntroduction[0]
            } else {
                this.$message.error(res.data);
            }
        });
      }
    },
    getBgStyle(item){
      if (item.picture) {
        return {
        backgroundImage:
          'url(' + item.picture + ') ',
        }
      } else {
        return {
        backgroundImage:
          'url(' +
          require('../../static/news-banner.jpg') +
          ') ',
        }
      }
    },
    // 获取图片地址
    // async getImage(row){
    //   if(row.picture){
    //     let res = await get("/file/oss-url/"+row.picture)
    //     row.pictureUrl = res.data
    //     row.onerror = null
    //   }
    // },
  getImage(row) {
      setTimeout(function () {
        if (!row.pictureUrl){
          request({
            url: "/file/download",
            method: "POST",
            data: {
                id:row.picture
            },
            isDownLoadFile: true,
            isParams: true,
            responseType: "blob"
          }).then(res=>{
            const blob = new Blob([res], {
                type:"application/pdf"
            });
            const linkNode = document.createElement("a");
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            row.pictureUrl =  linkNode.href 
          })
        }
      }.bind(this), 1000);
    },
  },
  mounted() {
    this.parentData = this.$route.query
    console.log(this.parentData);
    this.getData()
    // this.navIndex = this.$route.path
    // if (Cookies.get('ClinLan') == 'en') {
    //   this.language = 'English'
    // } else {
    //   this.language = '中文'
    // }
  },
}
</script>
<style lang="scss" scoped>
.inner{
  width: 1620px;
  margin:0 auto;
}
.columns{
  padding:100px 0
}
.center-header{
  text-align: center;
    h3{
      font-size: 48px;
      line-height: 90px;
    }
    span{
      font-size: 22px;
      font-weight: 300;
    }
}

// banner
.banner{
  background-color: #F2F7FD;
  background-image: url('../../static/news-banner.jpg');
  background-size:cover;
  width: 100%;
  position: relative;
  height: 410px;
  overflow: hidden;
.left{
  position: absolute;
  left: 250px;
  width:32%;
  top:110px;
  color:#fff;

  h2{
    font-weight: 800;
    font-size: 76px;
  }
  .intro-text{
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    margin-top:20px;
  }
}
}
.head-title{
    font-size: 74px;
    font-weight: bold;
    line-height: 140px;
    color: #0E63DC;
  }
.about{
  .about-box{
    margin-top: 80px;
  }
  .left{
    // width:40%;
    .img{
      width: 896px;
      height: 450px;
    }
    img{
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .right{
    padding:0 0 0 80px;
    overflow: hidden;
    height: 450px;
    overflow: scroll;
    overflow-x: hidden;
    .title{
      font-size: 74px;
      font-weight: bold;
      line-height: 140px;
      color: #0E63DC;
    }
    .content{
      line-height: 50px;
      font-size: 24px;
      p{
        margin-bottom: 30px;
      }
    }
  }
  // .right::-webkit-scrollbar {
  //   display: none;
  // }
  // .right  {
  //  -ms-overflow-style: none;
  // }
}
</style>
